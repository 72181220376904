<div *ngIf="stateService.selected.state == 'list'">
  <h1>Template List</h1>
  <div class="table-responsive">
    <table class="h-100">
      <tr>
        <th>
          <button class="btn btn-primary btn-block" (click)="stateService.setState('create', newTemplate)">Create</button>
        </th>
        <th>data</th>
      </tr>
      <tr *ngFor="let x of dataService.templates.collectionData">
        <td><button class="btn btn-primary btn-block" (click)="stateService.setState('deprecate', x)">delete</button></td>
        <td>
          <p>{{x | json}}</p>
        </td>
        <td><button class="btn btn-primary btn-block" (click)="stateService.setState('read', x)">Read</button></td>
      </tr>
    </table>
  </div>
</div>

<div *ngIf="stateService.selected.state == 'read'">
  <button class="btn btn-primary btn-block" (click)="stateService.setState('list', null)">back</button>
  <h1>Template Read</h1>
  <p>{{stateService.selected.data | json}}</p>
  <button class="btn btn-primary btn-block" (click)="stateService.setState('update', stateService.selected.data)">Edit</button>
</div>

<div *ngIf="stateService.selected.state == 'create' || stateService.selected.state == 'update'">
  <button class="btn btn-primary btn-block" (click)="stateService.setState('list', null)">cancel</button>
  <h1 *ngIf="stateService.selected.state == 'create'">Template Create</h1>
  <h1 *ngIf="stateService.selected.state == 'update'">Template Update</h1>
  <form #TemplateForm="ngForm" (ngSubmit)="TemplateForm.form.valid && submitTemplate()" novalidate>
    <input #templateName="ngModel" required [ngClass]="{ 'is-invalid': TemplateForm.submitted && templateName.invalid }" [(ngModel)]="stateService.selected.data.templateName" class="form-control" type="text" name="templateName" id="templateName"
      placeholder="text" autofocus>
    <div *ngIf="TemplateForm.submitted && templateName.invalid" class="invalid-feedback">
      <div *ngIf="templateName.errors.required"> required</div>
    </div>
    <button class="btn btn-primary btn-block">Save</button>
  </form>
</div>

<div *ngIf="stateService.selected.state == 'deprecate'">
  <button class="btn btn-primary btn-block" (click)="stateService.setState('list', null)">cancel</button>
  <h1>Template Deprecate</h1>
  <p>{{stateService.selected.data | json}}</p>
  <button class="btn btn-primary btn-block" (click)="removeTemplate(stateService.selected.data)">Confirm</button>
</div>
