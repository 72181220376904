// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyADuw0APGqBGBhqOEw-9f5l09Ri8WAThOo",
       authDomain: "at-call-website.firebaseapp.com",
       databaseURL: "https://at-call-website.firebaseio.com",
       projectId: "at-call-website",
       storageBucket: "at-call-website.appspot.com",
       messagingSenderId: "425770751223",
       appId: "1:425770751223:web:6b147e180d807aae44aff2",
       measurementId: "G-9862NW3E6V"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
