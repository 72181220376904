import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  TemplateComponent, // copy paste and replace me - dont overwrite
  HomeComponent, // copy paste and replace me - dont overwrite
} from '../components/';

const routes: Routes = [
  { path: 'template', component: TemplateComponent },
  { path: '**', component: HomeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RouterService { }
