import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

import { DataService } from './data.service'
import { StateService } from './state.service'

@Injectable()

export class AuthService {
  public userModel: Observable<firebase.User>;
  public user: firebase.User = null;

  constructor(
    public dataService: DataService,
    private stateService: StateService,
    private afAuth: AngularFireAuth,
  ) {
    this.userModel = afAuth.authState;
    this.userModel.subscribe(
      (user) => {
        if (user) {
          this.user = this.checkUserPermissions(user);
        } else {
          this.user = null;
        }
      }
    );
  }


  signInWithFacebook() {
    return this.afAuth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  signInWithGoogle() {
    return this.afAuth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    );
  }

  isLoggedIn() {
    if (this.user == null) {
      return false;
    } else {
      return true;
    }
  }

  logout() {
    this.stateService.selected.user = null;
    this.afAuth.signOut();
  }

  checkUserPermissions(userData) {
    if (userData.email.includes('@futuresquared.com.au')) {
      console.log(userData.email)
      return userData;
    } else {
      return null;
    }
  }

}
