import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

import {
  //data config models
  TemplateDataModel,
  //object models
  TemplateModel,
} from '../models';

@Injectable()
export class DataService {


  constructor(
    private afs: AngularFirestore,
  ) { }


  //data model collections go here
  templates = TemplateDataModel;

  // call for all data you want on init of app
  getDatas() {
    this.getTemplateData();
  }


  //data collections calls  go here
  getTemplateData() {
    // //this component can be added multiple times for each collection you need to return - it also has different types
    // //this is the default return of a colletion - it will return all documents in a  collection
    this.getCollection(`${this.templates.collectionName}`).subscribe(data => {
      this.templates.collectionData = data.map(e => {
        return {
          FBUID: e.payload.doc.id,
          ...e.payload.doc.data() as TemplateModel
        }
      });
      this.templates.collectionData.sort(function(a, b) {
        if (a.templateName < b.templateName) {
          return -1;
        }
        if (a.templateName > b.templateName) {
          return 1;
        }
        return 0;
      });
    });
    // //this is the ordered list return of a colletion - it will return all documents in a  collection ordered by a data object in the model and a value of up (asc) or down (desc)
    // this.getCollectionWithSort(`${this.collectionName}`, `${this.filterObject}`, `${this.filterOrder}`).subscribe(data => {
    //   this.templates = data.map(e => {
    //     return {
    //       FBUID: e.payload.doc.id,
    //       ...e.payload.doc.data()
    //     } as TemplateModel;
    //   })
    // });
    //this is the filtered return of a colletion - it will return all documents in a  collection with by a specific value
    // this.getCollectionWithFilter(`${this.collectionName}`, `${this.filterObject}`, `${this.filterValue}`).subscribe(data => {
    //   this.templates = data.map(e => {
    //     return {
    //       FBUID: e.payload.doc.id,
    //       ...e.payload.doc.data()
    //     } as TemplateModel;
    //   })
    // });
    // //this is a single document return - it will give you a document with a specific id from a collection
    // this.getDoc(`${this.collectionName}/${this.documentId}`).subscribe(data => {
    //   this.newTemplate = {
    //     FBUID: data.payload.id,
    //     ...data.payload.data()
    //   } as TemplateModel;
    //   console.log(this.newTemplate);
    // })
  }


  // collection listing and and filtering - arrays
  getCollection(collectionName) {
    return this.afs.collection(`${collectionName}`).snapshotChanges();
  }

  getCollectionWithSort(collectionName, filterData, filterType) {
    return this.afs.collection(`${collectionName}`, ref => ref.orderBy(filterData, filterType)).snapshotChanges();
  }

  getCollectionWithFilter(collectionName, filterData, filterType) {
    return this.afs.collection(`${collectionName}`, ref => ref.where(filterData, "==", filterType)).snapshotChanges();
  }

  getDoc(documentId) {
    return this.afs.doc(`${documentId}`).snapshotChanges();
  }

  createDoc(collectionName, docData) {
    delete docData.FBUID;
    return this.afs.collection(`${collectionName}`).add(JSON.parse(JSON.stringify(docData)));
  }

  setDoc(collectionName, docData) {
    return this.afs.doc(`${collectionName}/${docData.FBUID}`).set(JSON.parse(JSON.stringify(docData)));
  }

  updateDoc(collectionName, docData) {
    return this.afs.doc(`${collectionName}/${docData.FBUID}`).update(JSON.parse(JSON.stringify(docData)));
  }

  deleteDoc(collectionName, docData) {
    return this.afs.doc(`${collectionName}/${docData.FBUID}`).delete();
  }
}
