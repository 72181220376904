<div class="container-fluid">
  <!-- nav small screen -->
  <nav class="navbar navbar-dark navbar-expand-lg d-lg-none fixed-top bg-dark">
    <a class="navbar-brand" href="#">
      <img class="img-fluid" style="height: 34px" src="../assets/img/logo-typo-grey.png" alt="Professional Cleaning Sydeny - Get Quote" class="d-inline-block align-top">
    </a>
    <ul class="nav">
      <li class="nav-item nav-link js-scroll-trigger" role="presentation"><a class="nav-link active js-scroll-trigger" href="#services">Services</a></li>
      <li class="nav-item nav-link js-scroll-trigger" role="presentation"><a class="nav-link active js-scroll-trigger" href="#contact">Quote</a></li>
    </ul>
  </nav>
  <!-- nav small screen ends -->

  <!-- nav large screen -->
  <nav class="navbar navbar-dark navbar-expand-lg d-none d-lg-block fixed-top bg-dark">

    <div class="row text-right">
      <a class="navbar-brand" href="#">
        <img class="img-fluid" style="height: 55px" src="../assets/img/logo-typo-grey.png" alt="Professional Cleaning Sydeny - Get Quote" class="d-inline-block align-top">
      </a>
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item nav-link js-scroll-trigger" role="presentation"><a class="nav-link active js-scroll-trigger" href="#services">Services</a></li>
        <li class="nav-item nav-link js-scroll-trigger" role="presentation"><a class="nav-link active js-scroll-trigger" href="#products">Products</a></li>
        <li class="nav-item nav-link js-scroll-trigger" role="presentation"><a class="nav-link active js-scroll-trigger" href="#partners">Partners</a></li>
        <li class="nav-item nav-link js-scroll-trigger" role="presentation"><a class="nav-link active js-scroll-trigger" href="#contact">Quote</a></li>
      </ul>
    </div>
  </nav>
  <!-- nav large screen ends -->

  <!-- landing section -->

  <!-- <div class="container-fluid d-none d-md-block" style="background: url('./assets/img/ACS-flatlay.png'); background-size: cover; background-color: rgba(0,0,0,0.5);">
    <div class="row vh-100" style="background-color: rgba(0,0,0,0.4);">
      <div class="col-lg-4 offset-lg-2 text-light text-center p-5">
        <img class="img-fluid w-100 mx-auto d-block mt-5" src="./assets/img/logo-port.png" alt="company-logo">
      </div>
    </div>
    <img class="img-fluid" style="width: 100%;" src="../assets/img/logo-port.png">
  </div> -->

  <div class="row vh-100" style="background: url('./assets/img/ACS-flat.png'); background-size: cover; background-position:right; center; background-color: rgba(0,0,0,0.5);">
    <div class="mt-4 mt-lg-5 p-5 col-12 col-md-4 offset-md-8 text-center">
      <img class="img-fluid" style="width: 100%;" src="../assets/img/logo-port.png" alt="Professional Cleaning Sydeny - Get Quote">
      <h5 class="text-dark">Quality finishes on all your projects.</h5>
      <!-- small screen button -->
      <p><a href="#st" class="btn btn-dark d-lg-none mt-4">WHAT OTHERS ARE SAYING</a></p>
      <!-- small screen button ends -->

      <!-- large screen button -->
      <p><a href="#lt" class="btn btn-dark d-none d-lg-block mt-4">WHAT OTHERS ARE SAYING</a></p>
      <!-- large screen button ends -->
    </div>
  </div>

  <!-- landing section ends -->

  <!-- testimonials large screen-->
  <div class="row bg-dark text-light d-none d-md-block d-lg-block" id="lt">
    <div class="col">
      <div class='row p-5'>
        <div class='col-1 d-flex align-items-center' (click)="setCount(-1)">
          <p><i class="fa fa-chevron-left"></i></p>
        </div>
        <div class="col-10 text-center p-5">
          <div *ngIf="testimonials[count]">
            <h4>{{testimonials[count].testimonialQuote}}</h4>
            <p class="mt-5">{{testimonials[count].testimonialName}}</p>
            <p>{{testimonials[count].testimonialCompany}}</p>
            <p><a href="#services" class="btn btn-light mt-4">VIEW OUR SERVICES</a></p>
          </div>
        </div>
        <div class='col-1 d-flex align-items-center' (click)="setCount(-1)">
          <p><i class="fa fa-chevron-right"></i></p>
        </div>
      </div>
    </div>
    <div class="custom-shape-divider-bottom-1592803733">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" stroke="#fff"
          stroke-opacity="0" fill="#ffffff"></path>
      </svg>
    </div>
  </div>
  <!-- testimonials large screen ends -->

  <!-- testimonials small screen-->
  <div class="row bg-dark text-light d-md-none" id="st">
    <div class='row p-5'>
      <div class='col-1 d-flex align-items-center' (click)="setCount(-1)">
        <p><i class="fa fa-chevron-left"></i></p>
      </div>
      <div class="col-10 text-center p-3">
        <div *ngIf="testimonials[count]">
          <h6>{{testimonials[count].testimonialQuote}}</h6>
          <p class="mt-5">{{testimonials[count].testimonialName}}</p>
          <p>{{testimonials[count].testimonialCompany}}</p>
          <p><a href="#services" class="btn btn-light mt-4">VIEW OUR SERVICES</a></p>
        </div>
      </div>
      <div class='col-1 d-flex align-items-center' (click)="setCount(-1)">
        <p><i class="fa fa-chevron-right"></i></p>
      </div>
    </div>
    <div class="custom-shape-divider-bottom-1592803733">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" stroke="#fff"
          stroke-opacity="0" fill="#ffffff"></path>
      </svg>
    </div>
  </div>
  <!-- testimonials small screen ends -->

  <!-- services -->
  <div class="row bg-white mt-5 p-lg-5" id="services">
    <div class="col text-center">
      <h2 class="text-dark mt-3">Services </h2>
      <p><a href="#contact" class="btn btn-dark mt-2">GET A QUOTE</a></p>
    </div>

    <div class="row mt-3">
      <div *ngFor="let x of services" class="col-sm-12 col-md-6 col-lg-3 mt-2">
        <div class="card m-4">
          <img class="card-img-top w-100 d-block" src="{{x.serviceImage}}" alt="Professional Cleaning Sydeny - Get Quote">
          <h5 class="mt-3">{{x.serviceTitle}}</h5>
          <p class="text-dark">{{x.serviceDescription}}</p>
          <p *ngIf="x.serviceLink == null" class="text-dark">{{x.servicePrice}}{{x.serviceRate}}</p>
          <a *ngIf="x.serviceLink != null" href="{{x.serviceLink}}" class="text-dark">{{x.servicePrice}}</a>
        </div>
      </div>
    </div>
  </div>
  <!-- services ends -->

  <!-- products -->
  <div class="row bg-white p-1 p-md-5" id="products">
    <div class="col text-center">
      <h2 class="text-dark">Products</h2>
    </div>

    <div class="row mt-5 px-md-5">
      <div *ngFor="let x of products" class="col-sm-12 col-md-6 mt-2">
        <div class="card px-4 my-4">
          <img class="card-img-top w-100 d-block" src="{{x.productImage}}" alt="Professional Cleaning Sydeny - Get Quote">
          <h5 class="mt-3">{{x.productTitle}}</h5>
          <p class="text-dark">{{x.productDescription}}</p>
          <a href="#contact" class="btn btn-light text-center">Talk to Us</a>
        </div>
      </div>
    </div>
  </div>
  <!-- products ends -->

  <!-- About/Contact -->
  <div class="row bg-light p-5">
    <div class="col">
      <div class="row p-md-5">
        <div class="col-xs-12 col-s-8 col-lg-6 p-lg-5">
          <i class="fa fa-users fa-2x my-5"></i>
          <h4 class="mt-2"> What We're All About </h4>
          <p class="mt-4"> At Call Services is a family owned and operated business in NSW Australia. We started up as a company in 2015 and have enjoyed helping our clients achieve high quality finishes on all their projects ever since.</p>
          <p>We pride ourselves on our premium quality services, communication and customer dedication. Our goal is to build strong, long lasting, working relationships with our builders and clients.</p>
          <p>At Call Services hires locally, allowing us to work with our local community and provide support where we can. We also work with disability service providers to try and get dedicated, hard working people into the workforce.</p>
        </div>
        <div id="contact" class="col-xs-12 col-s-4 col-lg-6 p-3 p-lg-5">
          <i class="fa fa-phone fa-2x my-5"></i>
          <h4 class="mt-2"> Call Us For Enquiries </h4>
          <p class="mt-4">0490 170 395 </p>
          <p>0427 394 045</p>
          <h4 class="mt-3"> Or Email </h4>
          <p>nathan@atcallservices.com.au</p>
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <p><a href="#partners" class="btn btn-dark d-none d-md-inline mt-2"><i class="fa fa-chevron-down"></i></a></p>
        </div>
      </div>
    </div>
  </div>


  <!-- About/Contact  ends -->

  <!-- partner logos -->
  <div id="partners" class="row p-5 bg-white">
    <div class="col text-center">
      <h3 class="text-dark p-5">Major Partners </h3>
      <div class="card-columns bg-transparent">
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_aberfoyle.png" alt="Professional Cleaning Sydeny - Get Quote - Aberfoyle">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_charleston.png" alt="Professional Cleaning Sydeny - Get Quote - Charleston">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_complete.jpg" alt="Professional Cleaning Sydeny - Get Quote - Complete Building">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_croftland.png" alt="Professional Cleaning Sydeny - Get Quote - Croftland">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_goop.png" alt="Professional Cleaning Sydeny - Get Quote - Goop Guys">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_highwater.png" alt="Professional Cleaning Sydeny - Get Quote - Highwater">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_hilton.png" alt="Professional Cleaning Sydeny - Get Quote - Hilton">
        </div>
        <div class="card bg-info p-1">
          <img class="card-img p-4" src="../assets/img/partner_manhattan.png" alt="Professional Cleaning Sydeny - Get Quote - Manhattan Homes">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_redrobin.png" alt="Professional Cleaning Sydeny - Get Quote - Redrobin">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_thermo.png" alt="Professional Cleaning Sydeny - Get Quote - Thermoshield">
        </div>
        <div class="card">
          <img class="card-img p-4" src="../assets/img/partner_winchester.png" alt="Professional Cleaning Sydeny - Get Quote - Winchester">
        </div>
      </div>
    </div>
  </div>
  <!-- partner logos ends -->

  <!-- gallery -->
  <div class="row  p-2">
    <div class="card-columns">
      <div class="card">
        <img class="card-img" src="../assets/img/ACS-1.jpg" alt="Construction Cleaning Service - Sydney">
      </div>
      <div class="card">
        <img class="card-img" src="../assets/img/ACS-2.jpg" alt="Construction Cleaning Service - Sydney">
      </div>
      <div class="card">
        <img class="card-img" src="../assets/img/ACS-3.jpg" alt="Construction Cleaning Service - Sydney">
      </div>
      <div class="card">
        <img class="card-img" src="../assets/img/ACS-4.jpg" alt="Construction Cleaning Service - Sydney">
      </div>
      <div class="card">
        <img class="card-img" src="../assets/img/ACS-5.jpg" alt="Construction Cleaning Service - Sydney">
      </div>
      <div class="card">
        <img class="card-img" src="../assets/img/ACS-6.jpg" alt="Construction Cleaning Service - Sydney">
      </div>
    </div>
  </div>
  <!-- gallery ends -->

  <!-- footer small screen-->
  <div class="row  pb-5 bg-light text-dark d-md-none">
    <div class="row pt-3">
      <div class="col-md-2 offset-md-1 text-center"><i class="fa fa-phone fa-2x text-center"></i>
      </div>
      <div class="col-md-3 text-center">
        <h2>Get Estimate</h2>
      </div>
      <div class="col-md-5 text-center">
        <h2 class="pb-5">0490 170 395</h2>
      </div>
    </div>
  </div>
  <!-- footer small screen ends -->

  <!-- footer large screen -->
  <div class="row  p-5 bg-light text-dark d-none d-md-block d-lg-block">
    <div class="row pt-5">
      <div class="col-md-2 offset-md-1 text-center"><i class="fa fa-phone fa-2x text-center"></i>
      </div>
      <div class="col-md-3 text-center">
        <h2>Get Estimate</h2>
      </div>
      <div class="col-md-5 text-center">
        <h2>0490 170 395</h2>
      </div>
    </div>
  </div>
  <!-- footer large screen ends -->
</div>
