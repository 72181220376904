import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  AuthService,
  DataService,
  StateService,
} from '../../services';


import {
  TemplateModel,
} from '../../models';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public stateService: StateService,
  ) {

  }

  // Data Models and Data Queries
  newTemplate: TemplateModel = new TemplateModel();

  ngOnInit() {
    // IF YOU NEED DATA WHEN THE COMPONENT LOADS, PUT THE FUNCTION HERE
    this.stateService.setState('list', null)
  }


  submitTemplate() {
    if (this.stateService.selected.state == 'update') {
      console.log(`updateTemplate(${this.stateService.selected.data.FBUID}) in`, `${this.dataService.templates.collectionName}`, `with this data`, this.stateService.selected.data)
      // this.dataService.updateDoc(`${this.dataService.templates.collectionName}`, this.stateService.selected.data);
      //use this if you want to select the document again in a read state
      this.stateService.setState('read', this.stateService.selected.data);
      //use this if you want to return to a list of documents
      // this.stateService.setState('list', null);
    } else {
      console.log(`addTemplate() in`, `${this.dataService.templates.collectionName}`, `with this data`, this.stateService.selected.data)
      this.dataService.createDoc(`${this.dataService.templates.collectionName}`, this.stateService.selected.data).then(docData => {
        this.stateService.selected.data.FBUID = docData.id;
        //use this if you want to select the document again in a read state
        this.stateService.setState('read', this.stateService.selected.data);
        //use this if you want to return to a list of documents
        // this.stateService.setState('list', null);
        //or comment out both to have the create form and list on the same page
        this.newTemplate = new TemplateModel();
      });
    }
  }

  removeTemplate(templateData) {
    console.log(`removeTemplate(${templateData.FBUID}) from`, `${this.dataService.templates.collectionName}`)
    this.dataService.deleteDoc(`${this.dataService.templates.collectionName}`, templateData)
    this.stateService.setState('list', null);
  }



}
