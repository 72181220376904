import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

// Firebase imports
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import * as firebase from '@angular/fire';

//service imports
import {
  AuthService,
  DataService,
  StateService,
  RouterService,
} from './services/';

//user generated templates for testing new fuctions
import {
  TemplateComponent, // copy paste and replace me - dont overwrite
  HomeComponent, // copy paste and replace me - dont overwrite
} from './components/';


@NgModule({
  declarations: [
    AppComponent,
    TemplateComponent, // copy paste and replace me - dont overwrite
    HomeComponent, // copy paste and replace me - dont overwrite
  ],

  imports: [
    BrowserModule,
    FormsModule,
    RouterService, // this has to be imported as it is not a provider as much as it is a service to run at all times.
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence(),
  ],
  providers: [
    AngularFirestore,
    AuthService,
    DataService,
    StateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
