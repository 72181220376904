import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
  AuthService,
  DataService,
  StateService,
} from '../../services';


import {
  // TemplateModel,
} from '../../models';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public stateService: StateService,
  ) {

  }

  count = 0;

  testimonials = [
    {
      testimonialQuote: '"We have been using At Call Services for a few years now and couldn’t be happier. They are a punctual, reliable, easy going team who show great attention with their streamlined cleans. We would highly recommend At Call services!',
      testimonialName: 'Daniel & Kristy Muscat',
      testimonialCompany: 'Complete Building Pty Ltd',
    },
    {

      testimonialQuote: '“Working with At Call Services has been great. I look forward to going to work everyday and I love working with the team we have. Everyday is different, never the same.”',
      testimonialName: 'Tiffany',
      testimonialCompany: 'Staff Member, At Call Services',
    },
    {

      testimonialQuote: '"The past 2 years working with Natalie and Nathan have been funny, exciting, hardworking with a laugh at the end of the day. They do their best to make sure all employees are okay, either at work or for personal reasons. I’ve loved working with these two!""',
      testimonialName: 'Charmaine',
      testimonialCompany: 'Staff Member, At Call Services',
    },
    {
      testimonialQuote: '"What I love about working with our dedicated team on site everyday is the fun and laughs we have along the way, while providing amazing services. Walking onto a site covered in mess, and walking out with it sparkling, really does make me proud”',
      testimonialName: 'Nathan',
      testimonialCompany: 'Director, At Call Services',
    },
    {

      testimonialQuote: '"I have had my cleaning business on and off for 20 plus years. Nothing gives me greater pleasure than to see our houses go from a construction site to a beautiful home. The satisfaction of knowing we did that is second to none.”',
      testimonialName: 'Natalie',
      testimonialCompany: 'Director, At Call Services',
    },

    {

      testimonialQuote: '"We have been using At Call Services for some time now after they were recommended to us. We could not be happier with all aspects of their service. I have found the quality of work completed to be of a very high standard with attention to detail, while Natalie and her staff are always professional and friendly. We wouldn’t hesitate to recommend At Call Services.”',
      testimonialName: 'Sharee Winckle',
      testimonialCompany: 'Red Robin Kitchens',
    },
    {
      testimonialQuote: '“Great cleaners with a professional attitude and friendly service.”',
      testimonialName: 'Chris Frewen',
      testimonialCompany: 'Winchester Homes',
    },
  ];

  services = [
    {
      serviceImage: './assets/img/service-office.png',
      serviceTitle: 'Office and Warehouse Cleaning Services',
      serviceDescription: 'Cleaning our clients office and warehouse spaces gives us pride and joy, helps them achieve their business goals in a clean happy environment and we make the place smell amazing.',
      servicePrice: '$55',
      serviceRate: '/hr',
    },
    {
      serviceImage: './assets/img/service-roof.png',
      serviceTitle: 'Roof Restorations and Spray Painting Services',
      serviceDescription: 'A new service we are offering is spray painting and rust conversion / prevention services, our highly experienced team has a combined 40 years painting in the commercial and residential sectors. ',
      serviceLink:'/#contact',
      servicePrice: 'Get In Touch',
      serviceRate: '',
    },
    {
      serviceImage: './assets/img/service-construction.png',
      serviceTitle: 'Construction Cleaning Services',
      serviceDescription: 'Walking into a filthy construction site, getting our professional team stuck in and leaving it with the site sparkling is an amazing feeling and achievement. Everyone who works with us understands our vision and has a positive attitude, it really shows in our quality.',
      servicePrice: '$55',
      serviceRate: '/hr',
    },
    {
      serviceImage: './assets/img/service-report.png',
      serviceTitle: '... included with every job',
      serviceDescription: 'On all our cleaning jobs we provide a detailed report highlighting specific areas that our cleaners come across, including any damages. Our clients, particularly our builders benefit from these reports they may otherwise have missed due to the area being dirty beforehand.',
      servicePrice: 'FREE',
      serviceRate: '',
    },
  ];

products = [
  {
    productImage: './assets/img/product-thermo.png',
    productTitle: 'Thermoshield',
    productDescription: 'Innovative, environmentally safe insulation coating made right here in Australia! Up to 45% temperature reduction and up to 40% energy cost savings, independently verified by  University of Melbourne and Western Australia, CSIRO and Engineers Australia, call us now to get this amazing product working for you! To learn more, visit https://www.thermoshield.com.au/',
    productURL: '',

  },
  {
    productImage: './assets/img/product-goop.png',
    productTitle: 'Goop Guys',
    productDescription: ' Goop Guys Services are a great way of protecting any surface temporarily during construction. Their flexible products are applied in a timely manner by their trained, professional franchisees. Goop Guys products save builders money and time in replacing damaged surfaces. If you are after an innovative, environmentally friendly product to protect your assets, give them a call today! To learn more, visit https://www.goopguys.com/',
    productURL: '',

  },

]
  // Data Models and Data Queries
  // newHome: HomeModel = new HomeModel();

  ngOnInit() {
    // IF YOU NEED DATA WHEN THE COMPONENT LOADS, PUT THE FUNCTION HERE
    // this.stateService.setState('list', null)

  }

  setCount(countData) {
    if (countData == -1) {
      this.count--;
    }
    else {
      this.count++;
      this.count = this.testimonials.length;
    }

    if (this.count < 0) {
      this.count = this.testimonials.length - 1;
    }
    console.log("settingCount", this.count);
  }

}
