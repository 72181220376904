import { Component, OnInit } from '@angular/core';
import { AuthService, DataService, StateService } from './services/';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  title = 'at-call-website' + this.stateService.selected.version;

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public stateService: StateService,
  ) { }

  ngOnInit() {
    this.dataService.getDatas();
    this.stateService.setComponent('template');
  }


}
