// copy paste and replace me - dont overwrite
export const TemplateDataModel = {
  collectionName: 'templates',
  collectionData: [],
  documentId: 'someSpecificFBUID',
  filterObject: 'approvalId',
  filterValue: 'thisValueId',
  filterOrder: 'desc',
}

// copy paste and replace me - dont overwrite
export class TemplateModel {
  FBUID: string;

  templateName: string;
  templateImage = 'assets/img/image-placeholder.jpg';
}
