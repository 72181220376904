import { Injectable } from '@angular/core';


@Injectable()
export class StateService {


  constructor() {
  }

  selected = {
    version: 'v9.0.3', // versioning for this project is based on the angular version for the major number (vx.) and changes from F2 in the minors
    user: null,
    component: null,
    state: null,
    data: null,
  }

  //NOTE: this is gonna get huge - consider how we can make this more functional
  resetComponent() {
    this.resetState();
    this.selected.component = null;
  }

  setComponent(component) {
    this.selected.component = component;
    console.log(this.selected)
  }

  resetState() {
    this.selected.state = null;
    this.selected.data = null;
  }

  setState(state, data) {
    this.resetState();
    this.selected.state = state;
    this.selected.data = data;
    console.log(this.selected)
  }


}
